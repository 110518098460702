import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./assets/scss/styles.scss";
import { routes } from "./routes";

const AppLayout = lazy(() =>
    import(/*webpackChunkName: "AppLayout"*/ "./layouts/AppLayout")
);
const Home = lazy(() => import(/*webpackChunkName: "Home"*/ "./pages/Home"));
const About = lazy(() => import(/*webpackChunkName: "About"*/ "./pages/About"));
const Work = lazy(() => import(/*webpackChunkName: "Work"*/ "./pages/Work"));
const NotFound = lazy(() =>
    import(/*webpackChunkName: "NotFound"*/ "./pages/NotFound")
);

const App = () => {
    return (
        <Suspense fallback={<div className="main"></div>}>
            <AppLayout>
                <Routes>
                    <Route exact path={routes.home} element={<Home />} />
                    <Route path={routes.about} element={<About />} />
                    <Route path={routes.work} element={<Work />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </AppLayout>
        </Suspense>
    );
};

export default App;
