// Import Core
import { combineReducers } from "redux";
// Reucers
import modalReducer from "./modal/modal.reducer";

const rootReducer = combineReducers({
    modal: modalReducer,
});

export default rootReducer;
