// Import Core
import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
// Import root-reducer
import rootReducer from "./root-reducer";
// Set Middelware
const middlewares = [];
if (process.env.NODE_ENV === "development") {
    middlewares.push(logger);
}

// Create Store
export const store = createStore(rootReducer, applyMiddleware(...middlewares));
