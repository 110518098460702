// Import Action types
import modalActiontypes from "./modal.types";
// Default State
const INITIAL_STATE = {
    modalStatus: false,
};

const modalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case modalActiontypes.OPEN_MODAL:
            return {
                ...state,
                modalStatus: action.payload,
            };
        case modalActiontypes.CLOSE_MODAL:
            return {
                ...state,
                modalStatus: action.payload,
            };
        default:
            return state;
    }
};

export default modalReducer;
